<template>
    <div class="clientcabin clientcabin-proposal">
        <v-container class="mb-md-8">
            <v-row justify="center" class="align-center pt-md-6">
                <v-col cols="12" lg="5" xl="4">
                    <p class="headline">
                        {{ customHeadline }}
                    </p>
                    <p class="text-subtitle-1">
                        Get more targeted buyer traffic from the biggest and
                        best traffic source in the world. Improves brand
                        presence online and increases conversions across all
                        traffic sources.
                    </p>
                    <v-btn
                        v-if="options?.booking_url"
                        :large="$vuetify.breakpoint.mdAndUp"
                        class="primary mr-4"
                        :href="options?.booking_url"
                    >
                        Book Appointment
                    </v-btn>

                    <v-btn
                        :large="$vuetify.breakpoint.mdAndUp"
                        color="semi-black"
                        class="white--text"
                        @click="scrollToPricing"
                    >
                        See Pricing
                    </v-btn>
                </v-col>
                <v-col cols="12" lg="4" xl="3">
                    <div class="banner-traffic px-4 py-2 text-h6 with-shadow">
                        <div>All time</div>
                        <span class="font-weight-bold">Organic traffic</span>
                        2,918/month

                        <v-img
                            eager
                            class="mx-n1 mt-2"
                            src="@/assets/img/clientcabin/proposal/banner-traffic-only.png"
                            contain
                        />
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="py-md-10 clientcabin-darkblue">
            <v-container class="white--text inverted-icons">
                <v-row justify="center">
                    <v-col class="text-center">
                        <p class="subheadline white--text">
                            Watch to See How It Works:
                        </p>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7">
                        <external-video-embed
                            :video="customVideo"
                            class="with-shadow"
                        />
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7">
                        <v-container>
                            <v-row justify="center">
                                <v-col cols="auto">
                                    <v-img
                                        eager
                                        width="48"
                                        src="@/assets/img/clientcabin/proposal/eye.png"
                                        contain
                                    />
                                </v-col>
                                <v-col class="text-subtitle-1">
                                    Use
                                    <strong>Our Organic Traffic Team</strong>
                                    to create, repurpose and distribute tailored
                                    content to enhance your brand reputation and
                                    drive more sales
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="auto">
                                    <v-img
                                        eager
                                        width="48"
                                        src="@/assets/img/clientcabin/proposal/target.png"
                                        contain
                                    />
                                </v-col>
                                <v-col class="text-subtitle-1">
                                    Drive Long Term Hyper-Targeted Visitors &
                                    Buyers By Publishing Regular Content
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="auto">
                                    <v-img
                                        eager
                                        width="48"
                                        src="@/assets/img/clientcabin/proposal/trust.png"
                                        contain
                                    />
                                </v-col>
                                <v-col class="font-weight-black font-italic">
                                    Get published on major sites like YouTube,
                                    Business Insider, and Spotify using video,
                                    audio-ads, infographics, slideshows,
                                    articles and blog posts
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="blueish py-md-10">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="text-center">
                        <div class="subheadline">
                            Create, Repurpose, Distribute
                            <br />
                            & Unlock More Sales
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="text-center subtitle">
                        With our talented team, media connections, and unique 3
                        step process we connect you with people who are looking
                        for your products and services.
                    </v-col>
                </v-row>
            </v-container>

            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="10" xl="7">
                        <v-container class="feature-list pa-0">
                            <v-row>
                                <v-col class="pa-0" cols="12" md="4">
                                    <v-card elevation="0" class="feature pa-4">
                                        <v-col cols="auto">
                                            <v-row>
                                                <v-col cols="auto">
                                                    <v-img
                                                        eager
                                                        width="44"
                                                        src="@/assets/img/clientcabin/proposal/icon-create.png"
                                                    />
                                                </v-col>
                                                <v-col
                                                    class="align-self-center pl-0 text-h6"
                                                >
                                                    Create
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col class="text-body-2">
                                            We discover what people want to buy
                                            from you and create the ideal
                                            content to capture their attention.
                                        </v-col>
                                    </v-card>
                                </v-col>
                                <v-col class="pa-0" cols="12" md="4">
                                    <v-card elevation="0" class="feature pa-4">
                                        <v-col cols="auto">
                                            <v-row>
                                                <v-col cols="auto">
                                                    <v-img
                                                        eager
                                                        width="44"
                                                        src="@/assets/img/clientcabin/proposal/icon-repurpose.png"
                                                    />
                                                </v-col>
                                                <v-col
                                                    class="align-self-center pl-0 text-h6"
                                                >
                                                    Repurpose
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col class="text-body-2">
                                            We repurpose this idea into a news
                                            article, blog post, video,
                                            infographic, slideshow and an audio
                                            ad from a single piece of seed
                                            content.
                                        </v-col>
                                    </v-card>
                                </v-col>
                                <v-col class="pa-0" cols="12" md="4">
                                    <v-card
                                        elevation="0"
                                        class="feature last pa-4"
                                    >
                                        <v-col cols="auto">
                                            <v-row>
                                                <v-col cols="auto">
                                                    <v-img
                                                        eager
                                                        width="44"
                                                        src="@/assets/img/clientcabin/proposal/icon-distribute.png"
                                                    />
                                                </v-col>
                                                <v-col
                                                    class="align-self-center pl-0 text-h6"
                                                >
                                                    Distribute
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col class="text-body-2">
                                            We distribute all this content
                                            across hundreds of trusted brands,
                                            sites and platforms across the
                                            internet such as Business Insider,
                                            YouTube, Spotify and Pinterest.
                                        </v-col>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="py-md-10">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <v-container>
                        <v-row justify="center" class="align-center">
                            <v-col cols="auto">
                                <v-img
                                    eager
                                    width="400"
                                    src="@/assets/img/clientcabin/proposal/distribution-diagram.png"
                                />
                            </v-col>
                            <v-col>
                                <h3 class="subheadline">
                                    Get Seen on Big Sites
                                </h3>
                                <span class="text-subtitle-1">
                                    We have helped businesses just like yours
                                    get organic traffic from Google, Bing, other
                                    search engines and publish your content on
                                    sites like YouTube, Apple Podcasts, Business
                                    Insider and more.
                                    <p>
                                        <br />
                                        Grow your organic traffic, sales, and
                                        brand reach & reputation.
                                    </p>
                                </span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>

        <div class="py-md-10 clientcabin-darkblue">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-10">
                        <v-container>
                            <v-row justify="center">
                                <v-col>
                                    <div class="partners-logo">
                                        <p
                                            class="text-h5 grey-light-darker--text"
                                        >
                                            Some of the brands we
                                            publish&nbsp;to
                                        </p>

                                        <ul class="justify-center">
                                            <li>
                                                <v-img
                                                    eager
                                                    src="@/assets/img/clientcabin/proposal/brands/logo-the-globe-and-mail.png"
                                                    alt="logo-the-globe-and-mail"
                                                />
                                            </li>
                                            <li>
                                                <v-img
                                                    eager
                                                    src="@/assets/img/clientcabin/proposal/brands/logo-business-insider.png"
                                                    alt="logo-business-insider"
                                                />
                                            </li>
                                            <li>
                                                <v-img
                                                    eager
                                                    src="@/assets/img/clientcabin/proposal/brands/logo-spotify.png"
                                                    alt="logo-spotify"
                                                />
                                            </li>
                                            <li>
                                                <v-img
                                                    eager
                                                    src="@/assets/img/clientcabin/proposal/brands/logo-daco.png"
                                                    alt="logo-daco"
                                                />
                                            </li>
                                            <li>
                                                <v-img
                                                    eager
                                                    src="@/assets/img/clientcabin/proposal/brands/logo-msn.png"
                                                    alt="logo-msn"
                                                />
                                            </li>
                                            <li>
                                                <v-img
                                                    eager
                                                    src="@/assets/img/clientcabin/proposal/brands/logo-apple-podcasts.png"
                                                    alt="logo-apple-podcasts"
                                                />
                                            </li>
                                            <li>
                                                <v-img
                                                    eager
                                                    src="@/assets/img/clientcabin/proposal/brands/logo-google-news.png"
                                                    alt="logo-google-news"
                                                />
                                            </li>
                                            <li>
                                                <v-img
                                                    eager
                                                    src="@/assets/img/clientcabin/proposal/brands/logo-youtube.png"
                                                    alt="logo-youtube"
                                                />
                                            </li>
                                            <li>
                                                <v-img
                                                    eager
                                                    src="@/assets/img/clientcabin/proposal/brands/logo-pinterest.png"
                                                    alt="logo-pinterest"
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container class="py-md-16">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <v-container>
                        <v-row justify="center" class="align-center">
                            <v-col>
                                <h3 class="subheadline">
                                    Content Marketing, But Better
                                </h3>
                                <p class="mt-4 text-subtitle-1">
                                    We publish content in the form of videos,
                                    articles, blogs, infographics, audio ads,
                                    and slideshows to 100s of sites online, so
                                    you'll be found across the internet and
                                    increase your organic traffic and sales.
                                </p>
                            </v-col>
                            <v-col cols="auto" class="pa-0">
                                <v-img
                                    eager
                                    width="340"
                                    src="@/assets/img/clientcabin/proposal/Content.png"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
        <div v-intersect="intersecter" class="py-md-10 clientcabin-darkblue">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="9" xl="7">
                        <v-container class="repurposes-tabs">
                            <v-row>
                                <v-col class="text-h5 grey-light-darker--text">
                                    Your Content in All These Formats:
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pa-0 pa-md-3">
                                    <span
                                        @mouseenter="stopLooping"
                                        @mouseleave="loopContentTabs"
                                    >
                                        <v-tabs
                                            v-model="tabs"
                                            :vertical="
                                                !$vuetify.breakpoint.mobile
                                            "
                                            color="grey-light-darker"
                                        >
                                            <v-tab class="text-subtitle-1">
                                                <v-img
                                                    max-width="16"
                                                    class="mr-4"
                                                    src="@/assets/img/clientcabin/proposal/modules/icon-file.svg"
                                                />
                                                Blog
                                            </v-tab>
                                            <v-tab class="text-subtitle-1">
                                                <v-img
                                                    max-width="16"
                                                    class="mr-4"
                                                    src="@/assets/img/clientcabin/proposal/modules/icon-edit.svg"
                                                />
                                                Articles
                                            </v-tab>
                                            <v-tab class="text-subtitle-1">
                                                <v-img
                                                    max-width="16"
                                                    class="mr-4"
                                                    src="@/assets/img/clientcabin/proposal/modules/icon-tv.svg"
                                                />
                                                Slideshow
                                            </v-tab>
                                            <v-tab class="text-subtitle-1">
                                                <v-img
                                                    max-width="16"
                                                    class="mr-4"
                                                    src="@/assets/img/clientcabin/proposal/modules/icon-headphone.svg"
                                                />
                                                Podcast
                                            </v-tab>
                                            <v-tab class="text-subtitle-1">
                                                <v-img
                                                    max-width="16"
                                                    class="mr-4"
                                                    src="@/assets/img/clientcabin/proposal/modules/icon-file-image.svg"
                                                />
                                                Infographics
                                            </v-tab>
                                            <v-tab class="text-subtitle-1">
                                                <v-img
                                                    max-width="16"
                                                    class="mr-4"
                                                    src="@/assets/img/clientcabin/proposal/modules/icon-video.svg"
                                                />
                                                Video
                                            </v-tab>

                                            <v-tab-item
                                                class="px-md-8 py-md-2 grey-light-darker--text"
                                            >
                                                <div class="text-h5 mb-4">
                                                    Blog
                                                </div>
                                                <p>
                                                    Connect your brand with our
                                                    high-authority blog sites.
                                                    Our team of writers
                                                    carefully write each blog
                                                    post to align with the
                                                    overall content plan.
                                                </p>
                                                <ul>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/logo-msn.png"
                                                            alt="msn"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/dailymoss.png"
                                                            alt="dailymoss"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/myspace.png"
                                                            alt="myspace"
                                                        />
                                                    </li>
                                                </ul>
                                            </v-tab-item>
                                            <v-tab-item
                                                class="px-md-8 py-md-2 grey-light-darker--text"
                                            >
                                                <div class="text-h5 mb-4">
                                                    Articles
                                                </div>
                                                <p>
                                                    Like our blog posts, a team
                                                    of writers carefully write
                                                    each article to align with
                                                    the overall content plan.
                                                </p>
                                                <ul>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/digital-journal.png"
                                                            alt="digital journal"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/logo-business-insider.png"
                                                            alt="business insider"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/yahoo.png"
                                                            alt="yahoo"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/medium.png"
                                                            alt="medium"
                                                        />
                                                    </li>
                                                </ul>
                                            </v-tab-item>
                                            <v-tab-item
                                                class="px-md-8 py-md-2 grey-light-darker--text"
                                            >
                                                <div class="text-h5 mb-4">
                                                    Slideshow
                                                </div>
                                                <p>
                                                    Our AI and machine learning
                                                    technology repurposes the
                                                    content created in the blog
                                                    and article formats to
                                                    create the slideshow. Before
                                                    submitting for approval, our
                                                    team of writers will
                                                    carefully edit each
                                                    slideshow to ensure it
                                                    matches with the original
                                                    topic and content plan.
                                                </p>
                                                <ul>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/SlideShare.png"
                                                            alt="slideshare"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/Calameo-logo.png"
                                                            alt="calameo"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/Issuu_logo.png"
                                                            alt="issuu"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/Box,_Inc.png"
                                                            alt="box"
                                                        />
                                                    </li>
                                                </ul>
                                            </v-tab-item>
                                            <v-tab-item
                                                class="px-md-8 py-md-2 grey-light-darker--text"
                                            >
                                                <div class="text-h5 mb-4">
                                                    Podcast
                                                </div>
                                                <p>
                                                    Our AI and machine learning
                                                    technology repurposes the
                                                    content created in the blog
                                                    and article formats to
                                                    create the Podcast. Our
                                                    writers review the script
                                                    before the audio ad is
                                                    created and sent for
                                                    approval.
                                                </p>
                                                <ul>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/spotify.png"
                                                            alt="spotify"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/apple-podcast.png"
                                                            alt="apple podcast"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/google-podcast.png"
                                                            alt="google podcast"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/podbean.png"
                                                            alt="podbean"
                                                        />
                                                    </li>
                                                </ul>
                                            </v-tab-item>
                                            <v-tab-item
                                                class="px-md-8 py-md-2 grey-light-darker--text"
                                            >
                                                <div class="text-h5 mb-4">
                                                    Infographics
                                                </div>
                                                <p>
                                                    Our AI and machine learning
                                                    technology repurposes the
                                                    content created in the blog
                                                    and article formats to
                                                    create the Podcast. Our
                                                    writers review the images
                                                    and content before the
                                                    infographic is created and
                                                    sent for approval.
                                                </p>
                                                <ul>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/pinterest.png"
                                                            alt="pinterest"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/diigo.png"
                                                            alt="diigo"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/scoop-it.png"
                                                            alt="scoop-it"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/imageShack.png"
                                                            alt="imageshackt"
                                                        />
                                                    </li>
                                                </ul>
                                            </v-tab-item>
                                            <v-tab-item
                                                class="px-md-8 py-md-2 grey-light-darker--text"
                                            >
                                                <div class="text-h5 mb-4">
                                                    Video
                                                </div>
                                                <p>
                                                    Our AI and machine learning
                                                    technology repurposes the
                                                    content created in the blog
                                                    and article formats to
                                                    create the Podcast. Our
                                                    writers review the text and
                                                    content before the final
                                                    Video is created and sent
                                                    for approval.
                                                </p>
                                                <ul>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/logo-youtube.png"
                                                            alt="youtube"
                                                        />
                                                    </li>
                                                    <li>
                                                        <v-img
                                                            src="@/assets/img/clientcabin/proposal/brands/vimeo.png"
                                                            alt="vimeo"
                                                        />
                                                    </li>
                                                </ul>
                                            </v-tab-item>
                                        </v-tabs>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container class="py-md-16">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <v-container>
                        <v-row justify="center">
                            <v-col cols="auto">
                                <v-img
                                    eager
                                    width="400"
                                    src="@/assets/img/clientcabin/proposal/family-plans.jpg"
                                />
                            </v-col>
                            <v-col>
                                <h3 class="subheadline">
                                    Attract People Looking to Buy Your Stuff
                                </h3>
                                <p class="pt-4 text-subtitle-1">
                                    87% of people do research online before
                                    making a buying decision.
                                </p>

                                <p class="text-subtitle-1">
                                    We figure out exactly what your customers
                                    are researching and searching for. We then
                                    publish content all over the internet so
                                    they find you and buy your products.
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="mb-md-8">
            <v-row justify="center">
                <v-col class="d-flex justify-center">
                    <buyer-journey />
                </v-col>
            </v-row>
        </v-container>

        <div class="py-md-10 blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7">
                        <v-container>
                            <v-row justify="center">
                                <v-col>
                                    <h3 class="subheadline">
                                        Build Brand Authority & Trust
                                    </h3>
                                    <p class="mt-4 text-subtitle-1">
                                        When more people, brands and popular
                                        websites talk about you, then both your
                                        target market and Google start to trust
                                        you more. Increased trust leads to more
                                        sales and the option to charge higher
                                        prices.
                                    </p>
                                </v-col>
                                <v-col cols="auto" class="pa-0">
                                    <v-img
                                        eager
                                        width="400"
                                        src="@/assets/img/clientcabin/proposal/people-trust.jpeg"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7">
                        <v-container>
                            <v-row justify="center">
                                <v-col cols="auto">
                                    <v-img
                                        eager
                                        width="400"
                                        src="@/assets/img/clientcabin/proposal/GA Traffic (light).png"
                                    />
                                </v-col>
                                <v-col>
                                    <h3 class="text-right subheadline">
                                        <div>Done-For-You</div>
                                        Organic Traffic At Scale
                                    </h3>
                                    <p class="mt-4 text-subtitle-1">
                                        The more you publish about your
                                        products, the more sales you can make.
                                    </p>
                                    <p class="mt-4 text-subtitle-1">
                                        There are typically thousands of topics
                                        and queries relating to your products
                                        that you don't show up for. Now you can
                                        stack visibility & traffic by scaling
                                        content talking about your products.
                                    </p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container class="py-md-10">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <v-container>
                        <v-row justify="center">
                            <v-col class="text-center">
                                <p class="headline">
                                    Get news articles, blog posts, videos,
                                    infographics, slide shows and audio ads
                                    about your business published on sites like:
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col class="text-center">
                                <v-row>
                                    <v-col
                                        class="d-flex align-center justify-center"
                                    >
                                        <v-img
                                            eager
                                            max-width="160"
                                            src="@/assets/img/clientcabin/proposal/brands/spotify-color.png"
                                        />
                                    </v-col>
                                    <v-col
                                        class="d-flex align-center justify-center"
                                    >
                                        <v-img
                                            eager
                                            max-width="160"
                                            src="@/assets/img/clientcabin/proposal/brands/youtube-color.png"
                                        />
                                    </v-col>
                                    <v-col
                                        class="d-flex align-center justify-center"
                                    >
                                        <v-img
                                            eager
                                            max-width="160"
                                            src="@/assets/img/clientcabin/proposal/brands/googlenews-color.png"
                                        />
                                    </v-col>
                                    <v-col
                                        class="d-flex align-center justify-center"
                                    >
                                        <v-img
                                            eager
                                            max-width="160"
                                            src="@/assets/img/clientcabin/proposal/brands/vimeo-color.png"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>

        <div class="py-md-10 blueish">
            <v-container class="pa-md-0">
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7">
                        <v-container>
                            <v-row justify="center">
                                <v-col class="text-center">
                                    <p class="headline">
                                        Customer Success Story
                                    </p>
                                    <span
                                        class="text-subtitle-1 font-weight-bold"
                                    >
                                        In 1 year a medical device brand
                                        increased traffic by approximately
                                        20,000%. This has taken them from
                                        approximately $48,000 in sales per
                                        month, to $735,000 in sales per month.
                                        This is an increase of $8,300,000 in
                                        yearly sales.
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col>
                                    <v-img
                                        eager
                                        src="@/assets/img/clientcabin/proposal/GA Traffic (dark).png"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container class="px-md-0 py-md-10">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <v-container class="mb-md-8">
                        <v-row justify="center">
                            <v-col class="text-center">
                                <span class="text-h6">
                                    Increased organic traffic and sales bringing
                                    more of your ideal customers. And as you get
                                    more visible, your competition gets less
                                    visible.
                                </span>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col>
                                <external-video-embed
                                    video="https://fast.wistia.com/embed/iframe/nx2m05tseu"
                                    class="with-shadow"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>

        <div class="py-md-10 blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7">
                        <v-container class="proposals">
                            <v-row justify="center">
                                <v-col class="text-center mb-md-10">
                                    <p class="headline">What You Get:</p>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        eager
                                        contain
                                        src="@/assets/img/clientcabin/proposal/target-guide.png"
                                    />
                                    <p class="text-h6">
                                        Benefit From Long Term Organic Traffic
                                        Strategies That Work
                                    </p>
                                </v-col>
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        eager
                                        src="@/assets/img/clientcabin/proposal/grow.png"
                                        contain
                                    />
                                    <p class="text-h6">
                                        Get Results While You Focus On Running
                                        Your Business
                                    </p>
                                </v-col>
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        eager
                                        src="@/assets/img/clientcabin/proposal/eye.png"
                                        contain
                                    />
                                    <p class="text-h6">
                                        Get Seen In Places Your Competition Are
                                        Not
                                    </p>
                                </v-col>
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        eager
                                        src="@/assets/img/clientcabin/proposal/target.png"
                                        contain
                                    />
                                    <p class="text-h6">
                                        Get Ultra specific content that attracts
                                        ideal customers looking for what you
                                        sell
                                    </p>
                                </v-col>
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        eager
                                        src="@/assets/img/clientcabin/proposal/map.png"
                                        contain
                                    />
                                    <p class="text-h6">
                                        Receive More Local Traffic From Google
                                        Maps
                                    </p>
                                </v-col>
                                <v-col cols="12" md="4" class="proposal-card">
                                    <v-img
                                        eager
                                        src="@/assets/img/clientcabin/proposal/trust.png"
                                        contain
                                    />
                                    <p class="text-h6">
                                        Increase Trust & Credibility So That
                                        More People Choose To Spend Money With
                                        You
                                    </p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container class="pt-md-10">
            <v-row justify="center">
                <v-col id="typeform" class="d-flex justify-center">
                    <div
                        class="typeform"
                        data-tf-live="01J2GT4F74BCFBCZC1VHCWFC3J"
                    ></div>
                    <component
                        :is="'script'"
                        src="//embed.typeform.com/next/embed.js"
                    />
                </v-col>
            </v-row>
        </v-container>

        <v-container class="pt-md-10">
            <v-row justify="center">
                <v-col id="pricing" cols="12" lg="9" xl="7">
                    <pricing @order="onOrder" />
                </v-col>
            </v-row>
        </v-container>

        <div class="py-md-10 blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7">
                        <v-container class="faq pa-0">
                            <v-row>
                                <v-col class="text-center">
                                    <p class="headline">
                                        Frequently Asked Questions
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col
                                    :class="{
                                        'mb-n6': $vuetify.breakpoint.smAndDown
                                    }"
                                    cols="12"
                                    md="6"
                                >
                                    <v-expansion-panels
                                        v-for="(faq, index) in faq1"
                                        :key="index"
                                        flat
                                        class="mb-4"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header
                                                class="font-weight-medium text-subtitle-1"
                                            >
                                                {{ faq.question }}
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content
                                                class="text-body-2"
                                            >
                                                <!-- eslint-disable vue/no-v-html -->
                                                <span
                                                    class="black--text"
                                                    v-html="faq.answer"
                                                ></span>
                                                <!-- eslint-enable -->
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-expansion-panels
                                        v-for="(faq, index) in faq2"
                                        :key="index"
                                        flat
                                        class="mb-4"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header
                                                class="font-weight-medium text-subtitle-1"
                                            >
                                                {{ faq.question }}
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content
                                                class="text-body-2"
                                            >
                                                <!-- eslint-disable vue/no-v-html -->
                                                <span
                                                    class="black--text"
                                                    v-html="faq.answer"
                                                ></span>
                                                <!-- eslint-enable -->
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                    <v-expansion-panels flat class="mb-4">
                                        <v-expansion-panel>
                                            <v-expansion-panel-header
                                                class="font-weight-medium text-subtitle-1"
                                            >
                                                Which package should I get?
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content
                                                class="text-body-2"
                                            >
                                                <span class="black--text">
                                                    Not sure?
                                                    <a
                                                        @click="
                                                            scrollToTypeForm
                                                        "
                                                    >
                                                        Click here
                                                    </a>
                                                    to answer few short
                                                    questions to find out.
                                                </span>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';
import { getScrollParent } from '@/utils/helpers';

import { BuyerJourney } from '@/components/ClientCabin/BuyerJourney';
import { Pricing } from '@/components/ClientCabin/Pricing';
import { ExternalVideoEmbed } from '@/components/ExternalVideoEmbed';

import type { ClientCabin, ClientCabinOptions } from '@/types/ClientCabin';

import { AnalyticsEvents } from '@/components/ClientCabin/Analytics/AnalyticsEvents';

@Component({
    name: 'Proposal',
    components: {
        BuyerJourney,
        ExternalVideoEmbed,
        Pricing
    }
})
export default class Proposal extends Vue {
    faq1 = [
        {
            question: 'Will I need to write any content myself?',
            answer: `No. We have a team of expert writers who will not only research, but do the writing & content for you. They've been doing this for over a decade.  There's no need for you to write anything.`
        },
        {
            question: 'Can I use my own content?',
            answer: 'Yes we can publish your content if you choose, although it may need editing to get published on some sites.  However, we highly recommend that you let our team do it as we know what gets you results.'
        },
        {
            question: 'What happens after I order a package?',
            answer: `Our team will get in touch to start the process. We'll obtain some information from you and begin creating the campaign.  Also, submitting information about your business is optional.`
        },
        {
            question: 'What exact sites will you publish to?',
            answer: `Some of them are listed above but the exact sites we publish to may change over time.`
        }
    ];

    faq2 = [
        {
            question: `How will I know what work you've done?`,
            answer: `We'll submit reports to you so you can see where your campaign has been published along with all the links, and for ongoing recurring campaigns you also get a report on your visibility and traffic growth.`
        },
        {
            question:
                'What about the other forms of content?  Will I have to submit those?',
            answer: `No. We take the content we create and repurpose it into other forms of content such as slideshows, infographics, audio ads, videos, etc. We publish on 100's of sites which helps you be seen everywhere your customers are searching.`
        }
    ];

    tabs = 0;

    interval = 0;

    isLooping = false;

    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    @InjectReactive({
        from: 'defaults'
    })
    defaults!: ClientCabinOptions;

    @InjectReactive({
        from: 'track',
        default() {
            return () => void 0;
        }
    })
    track!: (event: string) => void;

    @InjectReactive({
        from: 'endpoint',
        default() {
            return '';
        }
    })
    endpoint!: string;

    get customHeadline() {
        return (
            this.options?.options?.custom_headline_long ||
            this.defaults.custom_headline_long
        );
    }

    get customVideo() {
        return (
            this.options?.options?.sales_video_url ||
            this.defaults.sales_video_url
        );
    }

    onOrder(product: 'one-off' | 'one-monthly' | 'four-monthly') {
        this.track(AnalyticsEvents.AddPaymentInfo);

        this.$http
            .post(this.endpoint, {
                selected_product: product
            })
            .then(({ data }) => {
                if (data.data.success) {
                    this.onSuccess(data.data);
                }
            });
    }

    onSuccess({ redirect }: { redirect?: string }) {
        if (redirect) {
            // payment page
            window.location.href = redirect;
        }
    }

    beforeDestroy() {
        this.stopLooping();
    }

    get intersecter() {
        return {
            handler: this.onTabsVisible,
            options: {
                threshold: [0.5]
            }
        };
    }

    onTabsVisible(
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserverEntry,
        isIntersecting: boolean
    ) {
        if (isIntersecting) {
            this.loopContentTabs();
        } else {
            this.stopLooping();
        }
    }

    loopContentTabs() {
        if (this.isLooping) {
            return;
        }

        this.isLooping = true;

        this.interval = setInterval(
            () => {
                if (this.tabs < 5) {
                    this.tabs++;
                } else {
                    this.tabs = 0;
                }
            },
            this.$vuetify.breakpoint.mobile ? 3000 : 2000
        );
    }

    stopLooping() {
        clearInterval(this.interval);

        this.isLooping = false;

        this.interval = 0;
    }

    scrollToPricing() {
        const element = document.getElementById('pricing');

        if (element) {
            const container = getScrollParent(element) as HTMLElement;

            if (container) {
                this.$vuetify.goTo(element, {
                    container
                });
            }
        }
    }

    scrollToTypeForm() {
        const element = document.getElementById('typeform');

        if (element) {
            const container = getScrollParent(element) as HTMLElement;

            if (container) {
                this.$vuetify.goTo(element, {
                    container
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.clientcabin-proposal::v-deep {
    background-color: $white;
    color: $black;

    .inverted-icons {
        .v-image__image {
            filter: invert(1);
        }
    }

    .banner-traffic {
        border: 1px solid #e7e7e7;
        border-radius: 9px;
        background-color: $white;
    }

    .feature-list .feature {
        color: $black;
        border: 1px solid #e7e7e7;
        border-radius: 9px;
        height: 100%;
        margin: 0 35px;

        &:not(.last):before {
            content: '';
            width: 50px;
            height: 16px;
            float: left;
            background: url('@/assets/img/clientcabin/proposal/arrow-right.png')
                no-repeat center center;
            background-size: auto 16px;
            position: absolute;
            top: 50%;
            right: -60px;
            transform: translate(0, -50%);
        }
    }

    .partners-logo {
        overflow: hidden;
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0;
            margin: 0 -36px -30px -36px;
            list-style: none;

            li {
                padding: 0 36px 30px 36px;
                margin: 0;
            }
        }
    }

    .repurposes-tabs {
        .v-tabs-bar,
        .v-tabs-items {
            background-color: transparent;
        }

        .v-tab {
            justify-content: left;
            text-transform: none;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0;
            margin: 0 -36px -30px -36px;
            list-style: none;

            li {
                padding: 0 36px 30px 36px;
                margin: 0;
            }
        }
    }

    .proposals {
        max-width: 1000px;
    }

    .proposal-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .v-image {
            line-height: 128px;
            max-height: 128px;
            margin-bottom: 40px;
        }
    }

    .typeform {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .faq {
        .v-expansion-panels {
            border: 1px solid $tertiary-color;
            border-radius: 9px;

            .v-expansion-panel {
                background-color: transparent;
            }
        }

        .v-expansion-panel-header {
            color: $black !important;

            .v-expansion-panel-header__icon svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    .with-shadow {
        box-shadow: 0 0 25px hsla(0, 0%, 0%, 0.3);
    }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .clientcabin-proposal::v-deep {
        .feature-list .feature {
            margin: 30px 0;
            height: auto;

            &:not(.last):before {
                content: '';
                width: 16px;
                height: 28px;
                background: url('@/assets/img/clientcabin/proposal/arrow-down.png')
                    no-repeat center center;
                background-size: auto 28px;
                top: auto;
                bottom: -42px;
                right: auto;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }
}
</style>
